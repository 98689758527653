<template>
  <UserProfileCardList category="passiveData" />
</template>

<script>
import UserProfileCardList from "../../components/UserProfileCardList.vue"

export default {
  components: {
    UserProfileCardList
  }
}
</script>